@import "shared";
.catalogItem{
  color: $primaryColor;
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
  padding: 30px 0 30px 0;
  text-decoration: none;
}
a:hover, a:visited, a:link, a:active{
  text-decoration: none;
  color: $primaryColor;
}

.activeCategory{
  background: $primaryColor;
  a:hover, a:visited, a:link, a:active{
    text-decoration: none;
    color: white !important;
  }
  &:hover{
    background: $primaryColor !important;
  }
}

.catalogLeftMenuHeader{
  font-size: 22px;
  background: $primaryColor;
  color: white;
  font-weight: 900;
  line-height: 1.1;
  text-align: center;
  padding: 10px 0 10px 0;
  margin-bottom: 2px;
}
.catalogMobileButton{
  width: 100%;
}

.catalogLeftMenuItem{
  color: $primaryColor;
  font-family: modern, serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 1.3;
  text-align: center;
  padding: 20px;
  &:not(:last-child){
    border-bottom: 1px solid #eee;
  }
  &:hover{
    background: rgba(250, 197, 177, 0.3);
  }
}

.catalogMobileMenuItem{
  color: $primaryColor;
  font-family: modern, serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 1.3;
  text-align: center;
  padding: 20px;
  &:not(:last-child){
    border-bottom: 1px solid #eee;
  }
}

.catalogLeftMenuContainer {
  border-right: solid 4px #777777;
  border-bottom: solid 4px #777777;
  padding: 0 20px 0 20px;
  margin: 40px 30px 40px 40px;
  background: white;

}

.catalogMobileMenuContainer {
  padding: 20px 20px 0 20px ;
  text-align: center;

}

.itemsListContainer{
  padding:20px;
}

.itemsListItem{
  margin: 10px;
  text-align: center;
}
.itemsListItemImage{
    width: 100%;
}
.itemDetailsHeader{
  text-align: center;
  font-size: 18pt;
  color: $primaryColor;
  font-family: Suez One, serif;
  font-weight: 600;
  padding: 20px 0 0 0;
}
.itemDetails{
  margin: 10px 0 10px 0;
}
.itemDetailsSpecsName{
  font-size: 14pt;
  color: $primaryColor;
  font-family: Suez One, serif;
  font-weight: 600;
}
.itemDetailsPrice{
  font-size: 14pt;
  font-family: Suez One, serif;
  font-weight: 600;
}
.itemDetailsPriceName{
  @extend .itemDetailsPrice;
  color: $primaryColor;
}
.itemDetailsPriceValue{
  @extend .itemDetailsPrice;
  color: $secondaryColorDarker;
}
.itemDetailsPriceDetails{
  font-size: 11pt;
  font-size: 11pt;
  font-family: Suez One, serif;
  font-weight: 600;
  color: $secondaryColorDarker;
}
.itemDetailsImage{
  width:100%;
  margin-top: 0px;
  margin-bottom: 10px;
}

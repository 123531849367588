$primaryColor: #e84c0e;
$secondaryColorDarker: #444444;
$secondaryColorLighter: #505050;

.box{
  text-align: center;
}

.bodyHeader {
  text-align: center;
  font-size: 18pt;
  color: $primaryColor;
  font-family: Suez One, serif;
  font-weight: 600;
  padding: 20px 0 20px 0;
}
.site, body,html{
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

@import "shared";

.headerBox{
  @extend .box;
  padding: 10px 0 20px 0;
}

.headerCompanyName{
  text-decoration: none;
  font-size: 28px;
  font-weight: bold;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
  color: $primaryColor;
  font-family: sans-serif;
  &:hover{
    color: lighten($primaryColor, 6%);
  }
}

.headerDescription{
  font-size: 13px;
  margin: 5px 5px 0 5px;
  font-family: Raleway, sans-serif;
  letter-spacing: 0.7px;
  line-height: 1.42857143;
  color: $secondaryColorLighter;
  font-weight: 600;
}

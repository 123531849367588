@import "shared";

.slider {
  height: 45vh;
  overflow: hidden;
  width: 100%;
  margin-top: 2px;
}

@keyframes showSlider {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }

}

%sliderImage {
  width: 100%;
  height: 45vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}

.sliderImage1 {
  @extend %sliderImage;
  background-image: url("../images/slider/slider4.png");
  background-position: right;
  z-index: 1;
  animation: sliderImage1 20s infinite;
}

.sliderImage2 {
  @extend %sliderImage;
  background-image: url("../images/slider/slider2.png");
  background-position: left;
  z-index: 3;
  animation: sliderImage2 20s infinite;
}

.sliderImage3 {
  @extend %sliderImage;
  background-image: url("../images/slider/slider5.png");
  background-position: right;
  z-index: 5;
  animation: sliderImage3 20s infinite;
}

.sliderImage4 {
  @extend %sliderImage;
  background-image: url("../images/slider/slider3.png");
  background-position: left;
  z-index: 7;
  animation: sliderImage4 20s infinite;
}

@keyframes sliderImage1 {
  from {
    right: 0;
    z-index: 1
  }
  74% {
    right: 0;
    z-index: 1
  }
  75% {
    right: 0;
    z-index: 9
  }
  95% {
    right: 0;
    z-index: 9
  }
  to {
    right: 200vh;
    z-index: 9
  }
}

@keyframes sliderImage2 {
  0% {
    left: 0;
    z-index: 3
  }
  74% {
    left: 0;
    z-index: 3
  }
  75% {
    left: 0;
    z-index: 11
  }
  80% {
    left: 200vh;
    z-index: 11
  }
  100% {
    left: 200vh;
    z-index: 11
  }
}

@keyframes sliderImage3 {
  0% {
    right: 0;
  }
  50% {
    right: 0;
  }
  55% {
    right: 200vh;
  }
  75% {
    right: 200vh;
  }
  76% {
    right: 0;
  }
  100% {
    right: 0;
  }
}

@keyframes sliderImage4 {
  0% {
    left: 0;
  }
  25% {
    left: 0;
  }
  30% {
    left: 200vh;
  }
  75% {
    left: 200vh;
  }
  76% {
    left: 0;
  }
  100% {
    left: 0;
  }
}

.sliderText1, .sliderText2, .sliderText3, .sliderText4 {
  position: absolute;
  text-shadow: 1px 1px 1px #222222;
  font-size: 3vw;
  @media (max-width: 600px) {
    font-size: 7vw;
    width: 70%;
  }
  font-style: italic;
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: white;
  z-index: 6;
  top: 50%;
  opacity: 0;
}

.sliderText1 {
  right: 10%;
  text-align: right;
  animation: sliderText1 20s infinite;
}

.sliderText2 {
  left: 10%;
  text-align: left;
  animation: sliderText2 20s infinite;
}

.sliderText3 {
  right: 10%;
  text-align: right;
  animation: sliderText3 20s infinite;
}

.sliderText4 {
  left: 10%;
  text-align: left;
  animation: sliderText4 20s infinite;
}

@keyframes sliderText1 {
  0% {
    opacity: 0
  }
  10% {
    opacity: 1
  }
  70% {
    opacity: 1
  }
  71% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@keyframes sliderText2 {
  0% {
    opacity: 0
  }
  30% {
    opacity: 0
  }
  40% {
    opacity: 1
  }
  80% {
    opacity: 1
  }
  81% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@keyframes sliderText3 {
  0% {
    opacity: 0
  }
  50% {
    opacity: 0
  }
  60% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}

@keyframes sliderText4 {
  0% {
    opacity: 0
  }
  75% {
    opacity: 0
  }
  85% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}

@import "shared";

.companyName{
  color: $primaryColor;
  font-weight: bolder;
  font-size: clamp(20px, 25px, 25px);
  text-align: center;
  font-family: sans-serif
}
.companyLocation{
  font-size: clamp(15px, 18px, 18px);
  font-style: italic;
  font-family: Suez One, serif;
  color: #333;
  text-align: center;
}

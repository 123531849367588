@import "shared";

.menuBox{
  @extend .box;
  background: $primaryColor;
}

.menu{
  background: $primaryColor;

}

.menuButton {
  padding: 10px !important;
  border: 0 !important;
  font-family: Oswald, sans-serif !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  letter-spacing: 0.5px !important;
  color: white !important;

  &:hover {
    background: lighten($primaryColor, 5%) !important;
  }
}
.menuButtonActive{
  @extend .menuButton;
  background: lighten($primaryColor, 5%) !important;
}


@import "shared";
.servicesPanel{
  background: $primaryColor;
  margin-top: 2px;
  padding: 50px;
}

.servicesPanelItem{
  padding: 20px;
  text-align: center;
}
.servicesPanelHeader{
  text-shadow: 1px 1px 1px #222222;
  font-size: clamp(25px, 3vw, 29px);
  font-style: italic;
  font-family: Oswald, sans-serif;
  font-weight: 600;
  transform: scale(0.6, 1);
  color: white;
  line-height: 1.5;
}

.servicesPanelDescription{
  padding: 0 3px;
  color: #eeeeee;
  font-size: clamp(17px, 14px, 18px);
  line-height: 1.5
}


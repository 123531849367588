@import "shared";

.footerPanel{
  background: $secondaryColorLighter;
  margin-top: 1px;
  padding: 50px;
  text-align: left;
}
.footerPanelHeader{
  text-shadow: 1px 1px 1px #222222;
  font-size: clamp(25px, 3vw, 29px);
  font-style: italic;
  font-family: Oswald, sans-serif;
  font-weight: 600;
  line-height: 1.1;
  transform: scale(0.7, 1);
  color: white;
}

.footerPanelDescription{
  padding: 0 3px;
  margin-bottom: 20px;
  color: #eeeeee;
  font-size: clamp(15px, 14px, 18px);
}
.footerPanelCopyright{
  background: $secondaryColorLighter;
  margin-top: 1px;
  text-align: center;
  padding: 20px;
}
.footerPanelCopyrightText{
  color: white;
  font-size: clamp(8px, 12px, 12px);
  font-style: italic;
  font-family: tahoma, sans-serif;
}
